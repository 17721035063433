import React from 'react';
import styled from '@emotion/styled';
import { FlexBox } from '@argo/principles';
import { MapMarkerIcon } from '@argo/ui-elements';
import { getMilesAway } from '../../utilities';

const GoogleLinkContainer = styled.a`
    margin-left: auto;
`;

const GoogleLink = ({ mapUrl, distanceFromSearch, onGoogleLinkClick, isKbb }) => (
    <GoogleLinkContainer
        className="text-link link-undecorated"
        href={mapUrl}
        target="_blank"
        rel="noopener"
        onClick={onGoogleLinkClick}
    >
        <FlexBox
            className="dealer-distance"
            horizontalSpacing="micro"
        >
            <MapMarkerIcon
                className="margin-collapse-right"
                color={isKbb ? 'darkBrightBlue' : '#E85B00'}
                size="16"
            />
            <div className="margin-collapse-left">
                {getMilesAway(distanceFromSearch)}
            </div>
        </FlexBox>
    </GoogleLinkContainer>
);

export default React.memo(GoogleLink);
