import { sendClick } from 'reaxl-analytics';
import { useBrand } from 'reaxl-brand';
import { kbbBrand, ddpContactType } from '../../../constants';
import { isEmpty } from '.';
import emailFetcher from '../../../fetchers/emailFetcher';
import { hashProps } from 'atc-js';
import { v5 as uuidv5 } from 'uuid';
import { getAnalyticsPageName } from '../../../utilities';

export default function useSubmitLead() {
    const { isBrand } = useBrand();
    const isKbb = isBrand(kbbBrand);

    const sendSubmitLead = (errorMessages, fieldValues, rest, actions, event) => {
        const countMessages = Object.values(errorMessages).filter((msg) => msg).length;
        if (countMessages === 0 && !isEmpty(fieldValues.subject)) {
            const partnerName = isKbb ? kbbBrand : 'ConsumerSite';
            const entryInformation = {
                emailAddress: fieldValues.fromEmailAddress,
                listingId: '',
                comments: fieldValues.comments,
                ownerId: rest.ownerId,
                date: new Date().toDateString(),
            };
            const hashedString = hashProps(entryInformation).toString();
            const formEventId = uuidv5(hashedString, uuidv5.URL);
            const contactType = ddpContactType.CONTACT_DEALER_FORM;
            rest.currentPageName = getAnalyticsPageName(rest.currentPageName, isKbb);
            emailFetcher({ ...rest, ...fieldValues, partnerName, formEventId, contactType }).then(() => {
                sendClick('ownerEmailSent', null, {
                    ...rest,
                    ...fieldValues,
                    partnerName,
                    formEventId,
                    deal: {},
                    par: 'ddv_lstg',
                    pageName: 'ddp',
                });
                actions.setShowingContactDealer({ isSsuccess: true, mesage: 'Success! Your Email has been sent.', showing: true });
            }, (err) => {
                actions.setShowingContactDealer({ isSsuccess: false, mesage: 'Fail! Email sending failed', showing: true });
            });
        }
        return countMessages;
    };
    return { sendSubmitLead };
}
