/* global window */
/* eslint-disable react/jsx-max-props-per-line */
import React, { useEffect, useCallback, memo } from 'react';
import { useBrand } from 'reaxl-brand';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import { mapDispatchToActionProp } from '@atc/modular-redux';
import { DOMAnimation } from 'atc-js';
import { useDevice } from '@bonnet/next/device';

import {
    LoadingIndicator,
    Heading,
} from 'reaxl';

import {
    FilteredResultsText,
} from 'reaxl-filters';

import {
    InventoryListingV2,
    InventoryListingPlaceholder,
} from 'reaxl-listing';

import { sendClick, clickTypes, DelayedImpressionObserver } from 'reaxl-analytics';
import { useRouter } from 'next/router';
import { useFeatures } from 'reaxl-features';
import querystring from 'querystring';
import { getWebPathname, lowerCaseQueryParam, getDynamicSpecifications } from '../../utilities';

import { getCertifiedDataByProductId } from 'axl-config';
import {
    authDuck,
    inventoryFiltersDuck,
    inventoryPaginationDuck,
    inventoryResultsDuck,
    savedInventoryDuck,
    inventoryActiveInteractionDuck,
    inventoryActiveEmailListingDuck,
    inventoryUrlsDuck,
} from '../../ducks';

import useCreateDealerDetailsPageEvent from '../../hooks/useCreateDealerDetailsPageEvent';
import { rebuildDealerDetailsPage } from '../../actions';

import SearchResultsEmailModalContainer from './SearchResultsEmailModalContainer';

import { numRecords } from '../../reference/options';
import { kbbBrand, atcBrand } from '../../constants';

const renderPlaceholders = () => Array.from({ length: 10 }, () => <InventoryListingPlaceholder />);

const InventorySearchResultsContainer = memo(({
    actions,
    currentPage,
    hasResults,
    isUpdating,
    numRecordsData,
    resultCount,
    results,
    savedListingIds,
    showPlaceholders,
    clickTypeQueryParam,
    hasMaxQuickSaveItems,
    isLoggedIn,
    filters,
}) => {
    const device = useDevice();
    const notXS = _get(device, 'greaterThan.xs', false);
    const createDealerDetailsPageEvent = useCreateDealerDetailsPageEvent();
    const router = useRouter();
    const {
        override_carfax_tile: [isOverrideCarfaxTileEnabled, { partner }],
    } = useFeatures([
        'override_carfax_tile',
    ]);
    const { isBrand } = useBrand();
    const isKbb = isBrand(kbbBrand);
    const filtersValues = _get(filters, 'values', {});
    const selectedMakeCode = _get(filtersValues, 'makeCodeList', '') || _get(filtersValues, 'makeCode', '');
    const isDynamicListingsEnabled = !selectedMakeCode;

    // for some reason paginationDuckCreator from atc-ui change default value from 25 to 24 lead to wrong value for our page
    // so we need reset the default value here
    numRecordsData.value = numRecordsData.value === 24 ? 25 : numRecordsData.value;

    useEffect(() => {
        DOMAnimation.jumpToTopOfPage();
    }, []);

    const getVehicleUrl = (listing) => {
        let vehicleUrl = _get(listing, 'href', '') + clickTypeQueryParam;
        if (vehicleUrl.includes('listingId')) {
            vehicleUrl = `/cars-for-sale/vehicle/${vehicleUrl.split('listingId')[1].split('&')[0].replace('=', '')}`;
        }
        return vehicleUrl;
    };

    const handleListingClick = useCallback((event, listing = {}) => {
        event.preventDefault();
        sendClick('inventoryClick', event, {
            par: 'dealerdetails',
            inventoryId: listing.id,
        });
        window.location = getVehicleUrl(listing);
    }, []);

    const handleSave = useCallback((event, wasSaved, index, inventory = {}) => {
        if (isLoggedIn || (!isLoggedIn && !wasSaved && !hasMaxQuickSaveItems)) {
            sendClick('inventorySave', event, {
                par: `ddv_lstg_${index + 1}`,
                index,
                wasSaved,
                inventoryId: inventory.id,
                listingType: inventory.listingType,
            });
        }

        if (wasSaved) {
            actions.unsaveInventoryItem(inventory);
        } else {
            actions.saveInventoryItem(inventory);
        }
    }, [isLoggedIn, hasMaxQuickSaveItems]);

    const handleCertifiedTileClick = useCallback((index, listing = {}) => async (event) => {
        event.stopPropagation();
        await sendClick('inventoryCertifiedTileClick', event, {
            epn: 'CERT',
            par: `ddv_lstg_${index + 1}`,
            inventoryId: listing.id,
        });
    }, [sendClick]);

    const handleProductTileClick = useCallback((listing, index) => async (event) => {
        event.stopPropagation();
        sendClick('inventoryProductClick', event, {
            epn: _get(listing, 'productTiles[0].epn', ''),
            inventoryId: listing.id,
            par: `ddv_lstg_${index + 1}`,
            partnerProduct: isKbb ? listing.owner.name : undefined,
            listing,
        });
    }, [sendClick]);

    const handleAccelerateClick = useCallback((listing) => async (event) => {
        event.stopPropagation();
        sendClick('inventoryProductClick', event, {
            epn: _get(listing, 'productTiles[0].epn', ''),
            inventoryId: listing.id,
        });
    }, [sendClick]);

    const handleProductLinkClick = useCallback((listing, index) => async (event) => {
        event.stopPropagation();
        sendClick('listingProductLinkClick', event, {
            epn: _get(listing, 'productTiles[0].epn', ''),
            inventoryId: listing.id,
            par: `ddv_lstg_${index + 1}`,
        });
    }, [sendClick]);

    const handleClearSelectionsClick = async () => {
        actions.clearFilters();
        actions.resetPagination();
        const data = await actions.rebuildDealerDetailsPage(router.push);
        createDealerDetailsPageEvent(data);
    };

    const handleEmailOwnerClick = useCallback((listing, parentId, clickType) => async () => {
        actions.setActiveEmailListingResults([listing.id]);
        actions.setActiveInteraction({
            ownerId: listing?.owner?.id,
            parentId,
            showEmailModal: true,
            clickType,
        });
    }, [actions.setActiveEmailListingResults, actions.setActiveInteraction]);

    const configureTiles = (items) => items.map((listing) => {
        const {
            certifiedProduct,
            productTiles = [],
            owner = {},
            vin,
            makeCode,
            make,
        } = listing;
        const cpoProduct = getCertifiedDataByProductId(certifiedProduct, makeCode);
        const websiteHref = (_get(listing, 'website.href', '')).replace(clickTypeQueryParam, '');
        const carfaxTile = productTiles.filter((tile) => tile.tileType.startsWith('CARFAX'));
        const carfaxTileImage = _get(carfaxTile, '[0].image.src', '/content/web/images/na/partner-tiles/255821.gif');
        const whitelabelCarfaxTile = {
            cmp: 'ec_pa_lgo',
            cprd: 'View_CERT',
            epn: 'carfaxVHR',
            image: {
                src: carfaxTileImage,
            },
            link: {
                href: `https://www.carfax.com/VehicleHistory/p/Report.cfx?partner=${partner}&vin=${vin}`,
                label: `View the Free CARFAX Report for this ${make.name}`,
                offsite: true,
                partner: true,
            },
            tileType: 'CARFAX_APPENDED',
        };

        const certifiedTiles = productTiles.filter((tile) => tile.tileType.startsWith('CERTIFIED')).map((tile) => ({
            ...tile,
            cmp: 'ec_pa_lgo',
            cprd: `${make.code}_CERT`,
            epn: 'CERT',
            image: {
                alt: _get(cpoProduct, 'cpoTile.alt', ''),
                src: _get(cpoProduct, 'cpoTile.src', ''),
            },
            link: {
                ...tile.link,
                href: `${websiteHref}&tileClick=true${clickTypeQueryParam}#warrantySection`,
                label: `${make.name} Certified Pre-Owned`,
                offsite: false,
                partner: false,
                target: '_self',
            },
        }));
        let filteredTiles = productTiles.filter((tile) => !tile.tileType.startsWith('CERTIFIED'));
        if (isOverrideCarfaxTileEnabled) {
            filteredTiles = filteredTiles.filter((tile) => !tile.tileType.startsWith('CARFAX'));
            filteredTiles.push(whitelabelCarfaxTile);
        }

        return {
            ...listing,
            owner,
            certifiedTiles,
            productTiles: filteredTiles.map((tile) => {
                const experianAppended = tile.tileType === 'EXPERIAN_APPENDED';
                let updatedHref = tile.link.href;

                if (experianAppended && tile.sid) {
                    const privateSeller = _get(listing, 'owner.privateSeller');

                    updatedHref = `${getWebPathname('experian')}?${querystring.stringify({
                        SID: tile.sid,
                        VIN: listing.vin,
                        brand: isKbb ? kbbBrand : atcBrand,
                        ps: privateSeller,
                        make: make.code,
                    })}`;

                    if (updatedHref.includes('brand=kbb')) {
                        updatedHref = updatedHref.replace('car-dealers', 'dealers');
                    }
                }

                updatedHref = updatedHref || `${websiteHref}${clickTypeQueryParam}`;

                return {
                    ...tile,
                    linkVisible: !_get(tile, 'image.src'),
                    link: {
                        ...tile.link,
                        href: updatedHref,
                    },
                };
            }),
        };
    });

    const listingsWithTiles = configureTiles(results);

    const getPaginatedListingIndex = (listIndex) => listIndex + 1 + ((currentPage - 1) * numRecordsData.value);

    const getHotFixDuplicatePackgTitle = ({ title, packages }) => {
        const firstOEMPackageName = packages ? packages[0]?.name : '';

        if (firstOEMPackageName && firstOEMPackageName.length < 30) {
            const packageRegex = new RegExp(`w/\\s*${firstOEMPackageName}`, 'g');
            const packageNameParts = title.match(packageRegex);

            packageNameParts?.forEach((item, index) => {
                if (index !== 0) title = title.replace(item, '');
            });
        }

        return title;
    };

    const renderResults = () => {
        listingsWithTiles.map((listing) => {
            if (_get(listing, 'owner.rating')) {
                delete listing.owner.rating;
            }
            if (_get(listing, 'owner.website')) {
                delete listing.owner.website;
            }
            const phoneValue = _get(listing, 'owner.phone.value');
            listing.owner.phone = { ...listing.owner.phone, isVisible: !!phoneValue };

            listing.title = getHotFixDuplicatePackgTitle(listing);
        });

        return listingsWithTiles.map((listing, index) => (
            <div
                className="col-xs-12 col-sm-4 display-flex margin-top-3"
                key={`listing-${listing.id}`}
            >
                <DelayedImpressionObserver
                    onChange={() => { }}
                    key={listing.id}
                    style={{ width: '100%' }}
                >
                    <InventoryListingV2
                        style={{ height: '100%' }}
                        key={listing.id}
                        listing={listing}
                        className="cursor-pointer display-flex flex-column"
                        listingCTA={{
                            href: getVehicleUrl(listing),
                            title: listing.title,
                            className: 'text-gray-base',
                        }}
                        onClick={(event) => handleListingClick(event, listing)}
                        onSaveToggle={!isKbb && ((event, data, isSaved) => handleSave(event, isSaved, index, data))}
                        isSaved={savedListingIds.includes(listing.id)}
                        data-qaid="cntnr-invntryLstg"
                        data-birf-log="none"
                        orientation="portrait"
                        parentId={`ddv_lstg_${index + 1}`}
                        isPhoneNumberCallable={!notXS}
                        onProductTileClick={handleProductTileClick(listing, index)}
                        onProductTileLinkClick={handleProductLinkClick(listing, index)}
                        onCertifiedTileClick={handleCertifiedTileClick(index, listing)}
                        onAccelerateClick={handleAccelerateClick(listing)}
                        paginatedListingIndex={getPaginatedListingIndex(index)}
                        emailOwnerProps={{
                            labelKey: 'checkAvailability',
                            onCTAClick: handleEmailOwnerClick(listing, `ddv_lstg_${index + 1}`, clickTypes.DEALER_INVENTORY),
                        }}
                        dynamicSpecifications={isDynamicListingsEnabled ? getDynamicSpecifications(filtersValues, listing) : []}
                    />
                </DelayedImpressionObserver>
            </div>
        ));
    };

    const renderNoResults = () => (
        <div className="margin-vertical-3 margin-vertical-md-4">
            <Heading>
                No results found
            </Heading>
            <div className="text-size-400">
                Remove some criteria from your search or
                <span
                    className="margin-left-2 text-link link-undecorated"
                    onClick={handleClearSelectionsClick}
                    role="presentation"
                >
                    start a new search
                </span>
            </div>
        </div>
    );

    const getResults = () => {
        if (hasResults) {
            return renderResults();
        }

        return showPlaceholders ? renderPlaceholders() : renderNoResults();
    };

    return (
        <div>
            <SearchResultsEmailModalContainer />
            <FilteredResultsText
                className="text-size-200"
                numRecordsData={numRecordsData}
                pageCount={currentPage}
                resultCount={resultCount}
            />
            <LoadingIndicator show={!isUpdating}>
                <div className="row display-flex flex-wrap margin-bottom-5">
                    {getResults()}
                </div>
            </LoadingIndicator>
        </div>
    );
});

function mapStateToProps(state) {
    const results = inventoryResultsDuck.selectors.getDuckState(state);
    const savedListingIds = savedInventoryDuck.selectors.getSavedInventoryIds(state);
    const inventory = inventoryResultsDuck.selectors.getActiveInventory(state);
    const inventoryUrlData = inventoryUrlsDuck.selectors.getDuckState(state);
    const urls = _get(inventoryUrlData, 'results', {});
    const isLoggedIn = authDuck.selectors.isLoggedIn(state);
    const hasMaxQuickSaveItems = savedInventoryDuck.selectors.hasMaxQuickSaveItems(state);
    const inventoryPaginationData = inventoryPaginationDuck.selectors.getDuckState(state);
    return {
        filters: inventoryFiltersDuck.selectors.getDuckState(state),
        isUpdating: results.loading,
        hasResults: results.count > 0,
        showPlaceholders: results.initialLoad,
        results: inventory.map((listing) => ({
            ...listing,
            href: urls[listing.id],
        })),
        filterValues: inventoryFiltersDuck.selectors.getFiltersValues(state),
        savedListingIds,
        resultCount: inventoryResultsDuck.selectors.getResultsCount(state),
        currentPage: _get(inventoryPaginationData, 'currentPage', 0),
        numRecordsData: { ...numRecords, value: _get(inventoryPaginationData, 'numRecords', 25) },
        clickTypeQueryParam: `&clickType=${_get(lowerCaseQueryParam(_get(state, 'router.location.query', {})), 'clicktype', clickTypes.DEALER_INVENTORY)}`,
        hasMaxQuickSaveItems,
        isLoggedIn,
    };
}

const mapDispatchToProps = mapDispatchToActionProp({
    clearFilters: inventoryFiltersDuck.creators.clearFilters,
    resetPagination: inventoryPaginationDuck.creators.resetPagination,
    rebuildDealerDetailsPage,
    saveInventoryItem: savedInventoryDuck.creators.saveInventoryItem,
    unsaveInventoryItem: savedInventoryDuck.creators.unsaveInventoryItem,
    setActiveInteraction: inventoryActiveInteractionDuck.creators.setKeys,
    setActiveEmailListingResults: inventoryActiveEmailListingDuck.creators.setActiveResults,
});

export default connect(mapStateToProps, mapDispatchToProps)(InventorySearchResultsContainer);
