import React from 'react';
import { OwnerChat, OwnerChatEvents } from 'reaxl-molecules';
import classnames from 'classnames';
import getPropsToChat from '../../utilities/getPropsToChat';
import { connect } from 'react-redux';
import { sendClick } from 'reaxl-analytics';

const DealerChat = (state) => {
    const chatProps = getPropsToChat(state, sendClick);

    return (
        <React.Fragment key="chat-cta">
            <OwnerChat
                {...chatProps}
                className={classnames({ 'text-size-500': false, 'btn-block': true })}
                showChatCTA
                key="chat-cta-owner-chat"
                uiContext="circle"
            />
            <OwnerChatEvents listing={chatProps.listing} />
        </React.Fragment>
    );
};
function mapStateToProps(state) {
    return state;
}
export default connect(mapStateToProps, null)(DealerChat);
