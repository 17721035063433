import { sendPage, sendEvent, sendImpressions, getConsumerMetadata } from 'reaxl-analytics';
import { useStore } from 'react-redux';

import {
    inventoryResultsDuck,
    inventoryFiltersDuck,
    inventoryPaginationDuck,
} from '../ducks';
import _get from 'lodash/get';
import { getTabName } from '../utilities';
import { TAB_ANALYTICS_NAME } from '../constants';

export default function useCreateDealerDetailsPageEvent() {
    const store = useStore();
    return async ({
        isInitialLoad = false,
        event,
    } = {}) => {
        const consumerMetadata = await getConsumerMetadata();
        const state = store.getState();
        const dataIsland = _get(state, 'birf.pageData', {});
        await sendPage({
            dataIsland,
            domainKey: 'dealerdetails',
            isInitialLoad,
            filterDuck: inventoryFiltersDuck,
            paginationDuck: inventoryPaginationDuck,
            resultsDuck: inventoryResultsDuck,
            event,
            data: {
                consumerMetadata,
            },
        });

        if (!isInitialLoad) {
            sendEvent({
                name: 'search',
                type: 'search',
                dataIsland,
            });
        }
        // send new inventory impressions
        if (getTabName(state) === TAB_ANALYTICS_NAME.sales) {
            sendImpressions({
                name: 'inventoryImpressions',
                data: { cmp: 'dealerdetails', isSingle: false },
                resultsDuck: inventoryResultsDuck,
            });
        }

    };
}
