import React, { memo } from 'react';
import styled from '@emotion/styled';
import _get from 'lodash/get';
import { connect } from 'react-redux';
import { mapDispatchToActionProp } from '@atc/modular-redux';
import { useDevice } from '@atc/react-device';
import { useBrand } from 'reaxl-brand';
import { kbbBrand, ANALYTICS_PAGE_NAME, dealerRatingPageType } from '../../constants';
import { withDevice } from '@bonnet/next/device';
import getConfig from 'next/config';
import {
    userDuck,
    inventoryActiveInteractionDuck,
    showContactDealerDuck,
    retainOwnerDuck,
    inventoryActiveOwnerDuck,
} from '../../ducks';
import { DealerDetailsTabContainer } from './tabs';
import { colors, Section, GridContainer, FlexBox, breakpoints } from '@argo/principles';
import { Heading, Button, EmailIcon } from '@argo/ui-elements';
import AddressLink from '../../components/addressLink';
import GoogleLink from '../../components/googleLink';
import PhoneLink from '../../components/phoneLink';
import KbbDealerRatingBadge from '../KbbDealerRatingBadge';
import { OwnerWebsiteCTA } from 'reaxl-molecules';
import { sendClick } from 'reaxl-analytics';
import { DealerDetailsBreadcrumbs } from '.';

const DetailHeadingContainer = styled(Section)`
    background-position: bottom;
`;

const ButtonSection = styled.div`
    margin: 0px !important;
    display: flex;
    flex-wrap: wrap;

    @media (max-width: ${breakpoints.md + 205}px) {
        min-height: fit-content;
    }
`;

const ATCButtonSection = styled.div`
  span {
    border: 1px solid #e85b00;
    border-radius: 4px !important;
  }
`;

const DealerHeadingSection = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0px 0px 10px 0px !important;
`;

const DealerLocationContainer = styled.div`
    display: flex;
    justify-content: space-between;
    > div {
        display: flex;
    } 
    p {
        font-weight: normal !important;
    }
`;

const DealerAddress = styled.div`
    padding-right: 20px;
    min-height: 24px;

`;

const DealerGoogleLink = styled.div`
    min-height: 24px;
`;

const DealerGoogleLinkMobile = styled.div`
    padding: 0px;
`;

const KbbDealerRatingBadgeContainer = styled(KbbDealerRatingBadge)`
    min-height: 22px;
    pointer-events: none;
`;

const RatingSection = styled.div`
    min-height: 22px;
`;

const getTabsPadding = (isXs) => (isXs ? '0' : '16px');
const buttonProps = {
    buttonType: 'tertiary',
    disabled: false,
    enableCapitalize: false,
    minimizeSize: false,
    target: '_self',
    text: 'Contact Dealer',
    href: '#contactDealerForm',
    'data-cmp': 'contactDealerHeading',
};

const renderHeading = (heading, owner, isKbb, isXs, actions, cityZip, pageId, onLocationClick, onPhoneClick) => {
    const { publicRuntimeConfig } = getConfig();
    const { location = {}, phone = {}, distanceFromSearch = 0, id, rating = {} } = owner;
    const kbbRatingValue = _get(rating, 'value', 0);
    const mapUrl = _get(location, 'map.href', '');
    const phoneNumber = _get(phone, 'value');
    const zip = _get(owner, 'location.address.zip', '');
    const ownerWebsiteCtaClassName = 'margin-horizontal-0 margin-right-2' + (isKbb ? ' text-size-500' : '');
    const kbbDealerRatingUrl = `${publicRuntimeConfig.kbbRatingBadgeUrl}&svocId=${id}&zipCode=${zip}&searchZip=${cityZip || zip}&pageId=${pageId}&pageType=${dealerRatingPageType}&pageName=ddp&debug=false&isImpression=false`;
    const hasLocation = Object.keys(location).length > 0;
    const onContactDealerEmailClick = (event) => {
        sendClick('ownerContactClick', event, { ownerId: owner.id, owner });
        if (isXs) {
            actions.setShowingContactDealer({ isSsuccess: false, mesage: '', showing: true });
        }
    };
    return (
        <Section
            data-cmp="DealerHeadingContent"
            bgColor={isKbb ? 'none' : 'white'}
            horizontalSpacingOverrides={{ left: '16px', right: '8px' }}
            verticalSpacingOverrides={{ top: '0px', bottom: '8px' }}
        >
            <Heading
                headingTag="h2"
                headingName="ContentHeadingExtra"
                enableBakeTitle={false}
            >
                {heading.citystateHeading}
            </Heading>
            <FlexBox
                justifyContent="space-between"
                flexWrap="wrap"
                className="margin-top-0 margin-horizontal-0"
                horizontalSpacing="none"
                verticalSpacing="none"
            >
                <DealerHeadingSection>
                    <Heading
                        headingTag="h1"
                        headingName="PageHeading"
                        enableTitleCase={false}
                    >
                        {heading.dealerHeading}
                    </Heading>
                    {hasLocation && (
                        <Section
                            fullWidth
                            bgColor="transparent"
                            horizontalSpacingOverrides={{ left: '0', right: '0' }}
                            verticalSpacingOverrides={{ top: '12px', bottom: '0px' }}
                            data-cmp="dealer-heading-location"
                        >
                            {kbbRatingValue > 0 && (
                                <RatingSection>
                                    <KbbDealerRatingBadgeContainer
                                        kbbDealerRatingUrl={kbbDealerRatingUrl}
                                        dataCmp="header-kbbDealerRating"
                                    />
                                </RatingSection>
                            )}
                            <DealerLocationContainer isKbb={isKbb}>
                                <DealerAddress data-cmp="address-link">
                                    <AddressLink
                                        location={location}
                                        onAddressClick={onLocationClick}
                                    />
                                </DealerAddress>
                                <DealerGoogleLink data-cmp="google-link">
                                    <GoogleLink
                                        mapUrl={mapUrl}
                                        distanceFromSearch={distanceFromSearch}
                                        onGoogleLinkClick={onLocationClick}
                                        isKbb={isKbb}
                                    />
                                </DealerGoogleLink>
                            </DealerLocationContainer>
                            {phoneNumber && (
                                <PhoneLink
                                    phoneNumber={phoneNumber}
                                    isXs={isXs}
                                    onPhoneClick={onPhoneClick}
                                />
                            )}
                            <DealerGoogleLinkMobile
                                data-cmp="google-link-mobile"
                            >
                                <GoogleLink
                                    mapUrl={mapUrl}
                                    distanceFromSearch={distanceFromSearch}
                                    onGoogleLinkClick={onLocationClick}
                                    isKbb={isKbb}
                                />
                            </DealerGoogleLinkMobile>
                        </Section>
                    )}
                </DealerHeadingSection>
                <ButtonSection
                    data-cmp="buttonSectionHeading"
                >
                    {owner.website?.href && (
                        <OwnerWebsiteCTA
                            uiContext="default"
                            href={owner.website?.href}
                            analyticsData={{
                                ownerId: owner.id,
                            }}
                            bsStyle="primary"
                            className={ownerWebsiteCtaClassName}
                        />
                    )}
                    {isKbb ? (
                        <Button
                            {...buttonProps}
                            sizeType="large"
                            iconPosition="right"
                            onClick={onContactDealerEmailClick}
                        >
                            <EmailIcon
                                color="darkBrightBlue"
                                size="18"
                            />
                        </Button>
                    )
                        : (
                            <ATCButtonSection>
                                <Button
                                    {...buttonProps}
                                    sizeType="regular"
                                    onClick={onContactDealerEmailClick}
                                    color="#e85b00"
                                />
                            </ATCButtonSection>
                        )}
                </ButtonSection>
            </FlexBox>
        </Section>
    );
};

const DealerDetailsContainer = memo(({
    actions = {},
    owner = {},
    retainOwner = {},
    cityZip,
    pageId,
}) => {
    const { isBrand } = useBrand();
    const isKbb = isBrand(kbbBrand);
    const device = useDevice();
    const isXs = _get(device, 'is.xs', false);
    const city = _get(owner, 'location.address.city', '');
    const state = _get(owner, 'location.address.state', '');
    const comma = state ? ', ' : '';
    const citystateHeading = `${city}${comma}${state}`;
    const dealerHeading = _get(retainOwner, 'name', owner.name);
    const heading = { citystateHeading, dealerHeading };
    const location = _get(owner, 'location', {});
    const hasLocation = Object.keys(location).length > 0;
    const tabsPadding = getTabsPadding(isXs);
    const cityscapeUrl = isKbb ? 'https://file.kbb.com/kbb/images/PageBackground/SVG/cityscape-2b.svg' : '';

    const onLocationClick = (event) => {
        sendClick('ownerLocation', event, { pageName: ANALYTICS_PAGE_NAME.DDP });
    };
    const onPhoneClick = (event) => {
        sendClick('ownerCall', event, { ownerId: owner.id, position: 'top', owner });
    };

    return (
        <GridContainer>
            <DetailHeadingContainer
                fullWidth
                bgColor="transparent"
                withGlobalNavSpacing
                cityscapeOffsetLeft
                cityscapeUrl={cityscapeUrl}
                backgroundSize="cover"
                horizontalSpacingOverrides={{ left: '0', right: '0' }}
                verticalSpacingOverrides={{ top: '0px', bottom: hasLocation ? '0px' : '8px' }}
            >
                <DealerDetailsBreadcrumbs />
                {renderHeading(heading, owner, isKbb, isXs, actions, cityZip, pageId, onLocationClick, onPhoneClick)}
            </DetailHeadingContainer>
            <Section
                fullWidth
                data-cmp="dealerDetailsTabContainerSection"
                horizontalSpacingOverrides={{ left: tabsPadding, right: tabsPadding }}
                verticalSpacingOverrides={{ top: '0', bottom: '0' }}
                bgColor={colors.neutral.offWhite}
            >
                <DealerDetailsTabContainer />
            </Section>
        </GridContainer>
    );
});

function mapStateToProps(state) {
    const activeOwner = inventoryActiveOwnerDuck.selectors.getDuckState(state);
    const retainOwner = retainOwnerDuck.selectors.getDuckState(state);
    const owner = _get(state.owners, activeOwner, {});
    const cityZip = _get(userDuck.selectors.getLocation(state), 'zip', '');
    const pageId = _get(state, 'birf.pageData.page.BIRF.pg', '');

    return {
        owner,
        retainOwner,
        cityZip,
        pageId,
    };
}

const mapDispatchToProps = mapDispatchToActionProp({
    setActiveInteraction: inventoryActiveInteractionDuck.creators.setKeys,
    setShowingContactDealer: showContactDealerDuck.creators.setShowingContactDealer,
});

export default withDevice(connect(mapStateToProps, mapDispatchToProps)(DealerDetailsContainer));
