import React from 'react';
import { connect } from 'react-redux';
import { mapDispatchToActionProp } from '@atc/modular-redux';
import styled from '@emotion/styled';
import _get from 'lodash/get';
import classnames from 'classnames';
import { useDevice } from '@atc/react-device';

import OperatingHours from '../../../components/OperatingHours/OperatingHours';
import { getSelectedTabFromState, transformsHoursFromServiceDealer } from '../../../utilities';
import { kbbBrand, chatProvider, TAB_NAME, ANALYTICS_PAGE_NAME } from '../../../constants';
import { ContactDealerModal } from '.';
import { showContactDealerDuck, serviceDealerDuck, inventoryActiveOwnerDuck } from '../../../ducks';
import { useBrand } from 'reaxl-brand';
import { colors, Theme, Row, Col, Section, GridContainer } from '@argo/principles';
import { Heading, Button, EmailIcon } from '@argo/ui-elements';
import { sendClick } from 'reaxl-analytics';
import DealerChat from '../../../components/dealerChat';
import AddressLink from '../../../components/addressLink';
import GoogleLink from '../../../components/googleLink';
import PhoneLink from '../../../components/phoneLink';
import { setPaddingTop, setPaddingLeft } from '../../../utilities/helpers';

// #region Start Styling for code below

const setPadding = (paddingTop, paddingLeft, isKbb) => `
    ${setPaddingTop(paddingTop)}
    ${setPaddingLeft(paddingLeft)}
`;

const setButtonStyle = (isKbb) => `
    button > span {
        border-radius: ${isKbb ? '4px' : '22.5px'};
        border: ${isKbb ? '0px' : '1px #E85B00 solid'};
        font-weight: ${isKbb ? 'normal' : '600'};
        font-size: 13px;
        min-height: ${isKbb ? '45px' : '35px'};
    }
`;

const HeadingTagline = styled.div`
    color: #505050 !important;
    font-family: "Montserrat",sans-serif;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 28px;
`;

const SubHeadingSection = styled.div`
    margin-left: 0px;
`;

const ButtonSection = styled.div`
    ${({ paddingTop, paddingLeft, isKbb }) => setPadding(paddingTop, paddingLeft, isKbb)}
`;

const ContactDealerSection = styled.div`
    ${({ isKbb }) => setButtonStyle(isKbb)}
`;

const DealerAddress = styled.div`
    display: flex;
    > div {
        display: inline-block;
        padding: 0 !important;
    }
    p {
        font-weight: normal !important;
    }
    span {
        font-size: 12px;
    }
`;

const getGoogleLinkPadding = (isXs) => (`margin-top: ${isXs ? 16 : 24}px;`);

const GoogleLinkSection = styled.div`
    ${({ isXs }) => getGoogleLinkPadding(isXs)}
`;

const getButtonProps = (isKbb) => {
    const backgroundColor = isKbb ? colors.neutral.offWhite : colors.neutral.white;
    const color = isKbb ? '#2D363E' : '#E85B00';

    return { backgroundColor, color };
};

const getAddressDistanceStyle = (isXs) => (isXs ? 'xs-mile' : 'md-mile');

const getHeadingStyle = (isXs) => (isXs ? 'text-left headingContainerXs' : 'text-left headingContainerMd');

const ContactDealerInfo = ({
    actions,
    owner = {},
    isChat,
    isServiceTab,
    hourTitle = isServiceTab ? 'Service Hours' : 'Hours of Operation',
    phoneTitle = isServiceTab ? 'Service Phone' : 'Phone',
    contactDealerHeading,
    serviceDealerData,
}) => {
    const { phone = '', departments = [] } = serviceDealerData;
    const device = useDevice();
    const isXs = _get(device, 'is.xs', false);
    const isSm = _get(device, 'is.sm', false);
    const { isBrand } = useBrand();
    const isKbb = isBrand(kbbBrand);
    const phoneFromListingOwner = _get(owner, 'phone.value', '');
    const location = _get(owner, 'location', {});
    const hours = isServiceTab && departments.length > 0 ? transformsHoursFromServiceDealer(departments) : _get(owner, 'hours', []);
    const hasLocation = Object.keys(location).length > 0;
    const mapUrl = _get(location, 'map.href', '');
    const distanceFromSearch = _get(owner, 'distanceFromSearch', 0);
    const addressDistance = getAddressDistanceStyle(isXs);
    const headingStyle = getHeadingStyle(isXs);
    const { backgroundColor, color } = getButtonProps(isKbb);
    const analyticsData = {
        inventoryId: owner.id,
    };

    const onLocationClick = (event) => {
        sendClick('ownerLocation', event, { pageName: ANALYTICS_PAGE_NAME.DDP });
    };

    const onPhoneClick = (event) => {
        sendClick('ownerCall', event, { ownerId: owner.id, position: 'bottom', owner });
    };

    const handleContactClick = (event) => {
        sendClick('ownerContactClick', event, analyticsData, 'contactUSCTA');
        actions.setShowingContactDealer({ isSsuccess: false, mesage: '', showing: true });
    };

    const buttonProps = {
        buttonType: 'tertiary',
        disabled: false,
        enableCapitalize: false,
        minimizeSize: false,
        sizeType: 'regular',
        target: '_self',
        text: 'Contact Us',
        width: '150px',
        iconPosition: 'left',
        backgroundColor,
        color,
        onClick: handleContactClick,
    };

    return (
        <div>
            <Theme>
                <Section
                    fullWidth
                    data-cmp="contactDealerInfo"
                    key="contactDealerInfoSection"
                    horizontalSpacingOverrides={{ left: '0', right: '16px' }}
                    verticalSpacingOverrides={{ top: '0', bottom: '0' }}
                >
                    <GridContainer
                        gap="sm"
                        alignItems="center"
                    >
                        {contactDealerHeading.length > 0
                            && (
                                <HeadingTagline
                                    data-cmp="dealerSlogan"
                                    headingName="SubsectionHeadingSemi"
                                    className={headingStyle}
                                >
                                    &#34;
                                    {contactDealerHeading}
                                    &#34;
                                </HeadingTagline>
                            )}
                        {
                            hasLocation && (
                                <Row key="addressLinkRow">
                                    <Col sm={8}>
                                        <Heading
                                            headingTag="h2"
                                            headingName="ContentHeadingExtra"
                                            textTransformation={false}
                                        >
                                            Address
                                        </Heading>
                                        <DealerAddress>
                                            <div
                                                data-cmp="addressLink"
                                            >
                                                <AddressLink
                                                    location={location}
                                                    onAddressClick={onLocationClick}
                                                />
                                            </div>
                                        </DealerAddress>
                                    </Col>
                                    <Col sm={4}>
                                        <DealerAddress>
                                            <GoogleLinkSection
                                                data-cmp="googleLink"
                                                isXs={isXs}
                                                className={classnames(addressDistance)}
                                            >
                                                <GoogleLink
                                                    mapUrl={mapUrl}
                                                    distanceFromSearch={distanceFromSearch}
                                                    onGoogleLinkClick={onLocationClick}
                                                    isKbb={isKbb}
                                                />
                                            </GoogleLinkSection>
                                        </DealerAddress>
                                    </Col>
                                </Row>
                            )
                        }
                        {phoneFromListingOwner && (
                            <Row key="phoneRow">
                                <Col sm={12}>
                                    <SubHeadingSection>
                                        <Heading
                                            headingTag="h2"
                                            headingName="ContentHeadingExtra"
                                        >
                                            {phoneTitle}
                                        </Heading>
                                        <div
                                            types="body"
                                            style={{ fontWeight: 'normal' }}
                                        >
                                            {phone.length > 0
                                                ? (
                                                    <PhoneLink
                                                        isXs={isXs}
                                                        phoneNumber={phoneFromListingOwner}
                                                        onPhoneClick={onPhoneClick}
                                                    />
                                                ) : ''}
                                        </div>
                                    </SubHeadingSection>
                                </Col>
                            </Row>
                        )}

                        {hours.length > 0 && (
                            <Row key="operationHoursRow">
                                <Col sm={(isXs || isSm) ? 12 : 8}>
                                    <SubHeadingSection
                                        data-cmp="operationHoursSection"
                                    >
                                        <Heading
                                            headingTag="h2"
                                            headingName="ContentHeadingExtra"
                                        >
                                            {hourTitle}
                                        </Heading>
                                        <OperatingHours hours={hours} />
                                    </SubHeadingSection>
                                </Col>
                            </Row>
                        )}
                        <Row key="buttonsRow">
                            {isXs && (
                                <Col sm={6}>
                                    <ContactDealerSection isKbb={isKbb}>
                                        <ButtonSection
                                            paddingLeft={0}
                                            paddingTop={0}
                                            isKbb={isKbb}
                                        >
                                            <Button {...buttonProps}>
                                                {isKbb && (
                                                    <EmailIcon
                                                        color="darkBrightBlue"
                                                        size="24"
                                                    />
                                                )}
                                            </Button>
                                        </ButtonSection>
                                    </ContactDealerSection>
                                </Col>
                            )}
                            <Col
                                sm={6}
                                className="dealerChatSection"
                            >
                                {isChat && (
                                    <SubHeadingSection>
                                        <DealerChat />
                                    </SubHeadingSection>
                                )}
                            </Col>
                        </Row>
                    </GridContainer>
                </Section>
            </Theme>
            <div>
                <ContactDealerModal />
            </div>
        </div>

    );
};

function mapStateToProps(state) {
    const activeOwner = inventoryActiveOwnerDuck.selectors.getDuckState(state);
    const owner = _get(state.owners, activeOwner, {});
    const selectedTab = getSelectedTabFromState(state);
    const isServiceTab = (selectedTab?.name === TAB_NAME.SERVICE);
    const serviceDealerStateData = serviceDealerDuck.selectors.getDuckState(state);
    const serviceDealerData = _get(serviceDealerStateData, 'data', '');
    return {
        owner,
        isChat: chatProvider.providers.includes(String(_get(owner, 'chat.provider', ''))),
        isServiceTab,
        contactDealerHeading: _get(owner, 'tagline', ''),
        serviceDealerData,
    };
}

const mapDispatchToProps = mapDispatchToActionProp({
    setShowingContactDealer: showContactDealerDuck.creators.setShowingContactDealer,
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactDealerInfo);
