import React, { useState, useEffect, useMemo } from 'react';
import { v1 as uuidv1 } from 'uuid';
import { useBrand } from 'reaxl-brand';
import { kbbBrand, TAB_NAME, EMAIL_SUBJECT, EMAIL_SUBJECTS } from '../../constants/index';
import { showContactDealerDuck, inventoryActiveOwnerDuck } from '../../ducks';
import { getErrorMessage, isEmpty } from './common';
import useSubmitLead from './common/useSubmitLead';
import { mask, type, inputType, requiredMessage } from './contactDealerConstant';
import { getSelectedTabFromState } from '../../utilities';
import { connect } from 'react-redux';
import { mapDispatchToActionProp } from '@atc/modular-redux';
import styled from '@emotion/styled';
import _get from 'lodash/get';
import { kbbTheme, atcTheme } from '@argo/themes';
import ReCAPTCHA from 'react-google-recaptcha';
import getConfig from 'next/config';
import { useCookies } from 'react-cookie';
import { getFirstPartyVistorId } from 'atc-js';

import { colors, Section, Theme, Row, Col, GridContainer } from '@argo/principles';

import {
    InnerLabelInputField,
    MultiLineInputField,
    Link,
    Heading,
    Paragraph,
    Dropdown,
    Button,
} from '@argo/ui-elements';

const ContactDealerContainer = styled.form`
    padding-left: 0px;
    padding-top: 0px;

    @media (min-width: 768px) {
        padding-top: 0px;
    }
`;

const InputFieldContainer = styled.div`
    input {
        font-size: 14px;
    }
`;

const CommentFieldContainer = styled.div`
    label {
        font-size: 14px;
    }
    textarea {
        font-size: 14px;
    }
`;

const ErrorMessage = styled.p`
    font-size: 10px;
    font-weight: 400;
    letter-spacing: 0.7px;
    line-height: 12px;
    color: #f43a36;
    padding-left: 16px;
    margin-bottom: 0px;
`;

const ErrorBox = styled.div`
    select {
        ${({ borderColor }) => `
            border: 1px ${borderColor || '#dcdcdc'} solid;
        `}
        font-size: 14px;
    }
`;

const PrivacyPolicyContainer = styled.div`
    margin-top: 10px;
    a {
        font-size: 12px;
    }
`;

const initialFieldValues = {
    subject: '',
    firstName: '',
    lastName: '',
    fromEmailAddress: '',
    phoneNumber: '',
    comments: '',
};

const ContactDealer = ({ heading, description, defaultSubjectValue, commentHeight, isModal = false, privatePolicyUrl, actions, isServiceTab = false, ...rest }) => {
    const { publicRuntimeConfig } = getConfig();
    const { recaptchaV2ClientSiteKey, recaptchaSiteKeyDev } = publicRuntimeConfig;

    const { isBrand } = useBrand();
    const isKbb = isBrand(kbbBrand);
    const [values, setValues] = useState(initialFieldValues);
    const { sendSubmitLead } = useSubmitLead();
    const [cookies = {}] = useCookies(['ATC_ID', 'pxa_ipv4']);
    const pixallFirstPartyId = getFirstPartyVistorId();

    let isCypress = false;

    if (typeof window !== 'undefined') {
        isCypress = window && window.Cypress && window.Cypress !== undefined;
    }

    useEffect(() => {
        initialFieldValues.subject = defaultSubjectValue;
        setValues(initialFieldValues);
    }, []);

    const [errorMessages, setErrorMessages] = useState({});
    const [subjectErrorMessage, setSubjectErrorMessage] = useState('');
    const [isValidateOnChange, setIsValidateOnChange] = useState(false);
    const [validateRecaptcha, setValidateRecaptcha] = useState(false);
    const handleValidate = (element) => {
        const errors = { ...errorMessages };
        const message = getErrorMessage(element, errors);
        setErrorMessages({ ...message });
    };

    const reCaptcha = useMemo(
        () => (
            <div style={{ textAlign: 'right' }}>
                <ReCAPTCHA
                    badge="inline"
                    id="reCAPTCHA"
                    sitekey={isCypress ? recaptchaSiteKeyDev : recaptchaV2ClientSiteKey}
                    size="normal"
                    onChange={() => {
                        setValidateRecaptcha(true);
                    }}
                    onExpired={() => {
                        setValidateRecaptcha(false);
                    }}
                />
            </div>
        ),
        []
    );

    const handleInputChange = (e) => {
        if (isValidateOnChange) {
            handleValidate(e.target);
        }

        const { name, value } = e.target;
        let fieldValue = {};
        fieldValue = { [name]: value };
        setValues({
            ...values,
            ...fieldValue,
        });
    };

    const handleSubjectChange = (subject) => {
        if (isValidateOnChange) {
            if (isEmpty(subject)) {
                setSubjectErrorMessage(requiredMessage);
            } else {
                setSubjectErrorMessage('');
            }
        }
        let fieldValue = {};
        fieldValue = { subject };
        setValues({
            ...values,
            ...fieldValue,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const inputFields = e.target.querySelectorAll('input');
        const errors = { ...errorMessages };
        let messages = {};
        inputFields.forEach((element) => {
            messages = getErrorMessage(element, errors);
            setErrorMessages({ ...messages });
        });

        if (isEmpty(values.subject)) {
            setSubjectErrorMessage(requiredMessage);
        }

        if (!isValidateOnChange) {
            setIsValidateOnChange(true);
        }

        if (validateRecaptcha) {
            const constructRestData = {
                ...rest,
                cookieId: cookies?.ATC_ID,
                ipAddress: cookies?.pxa_ipv4,
                pixallFirstPartyId,
            };
            sendSubmitLead(messages, values, constructRestData, actions, e);
        }
    };

    const onClickPrivacyPolicy = () => {
        window.open(privatePolicyUrl, 'popup', 'height=590,width=690,scrollbars=yes,resizable=yes', false);
    };

    return (
        <ContactDealerContainer
            id="contactDealerForm"
            data-cmp="contactDealerContainer"
            onSubmit={handleSubmit}
            noValidate
        >
            <Theme theme={isKbb ? kbbTheme : atcTheme}>
                <Section
                    fullWidth
                    data-cmp="contactDealerTitle"
                    horizontalSpacingOverrides={isModal && { left: '0px', right: '0px' }}
                    verticalSpacingOverrides={{ top: isModal ? '0px' : '24px', bottom: '0' }}
                    bgColor={isModal ? colors.neutral.white : colors.neutral.offWhite}
                >
                    <GridContainer
                        gap="xs"
                        alignItems="center"
                    >
                        {heading
                            && (
                                <Row>
                                    <Col sm={12}>
                                        <Heading
                                            headingTag="h3"
                                            headingName="SubsectionHeadingExtra"
                                        >
                                            {heading}
                                        </Heading>
                                    </Col>
                                </Row>
                            )}
                        {description
                            && (
                                <Row>
                                    <Col sm={12}>
                                        <Paragraph>
                                            {description}
                                        </Paragraph>
                                    </Col>
                                </Row>
                            )}
                    </GridContainer>
                </Section>
                <Section
                    fullWidth
                    data-cmp="contactDealerInfoForm"
                    horizontalSpacingOverrides={isModal && { left: '0px', right: '0px' }}
                    verticalSpacingOverrides={{ top: '16px', bottom: '16px' }}
                    bgColor={isModal ? colors.neutral.white : colors.neutral.offWhite}
                >
                    <GridContainer
                        gap="sm"
                        alignItems="center"
                    >
                        <Row>
                            <Col sm={12}>
                                <ErrorBox borderColor={subjectErrorMessage && 'red'}>
                                    <Dropdown
                                        name="subject"
                                        id="subject"
                                        placeholder="Select Subject"
                                        items={EMAIL_SUBJECTS}
                                        value={defaultSubjectValue}
                                        onChange={handleSubjectChange}
                                    />
                                </ErrorBox>
                                {subjectErrorMessage && <ErrorMessage>{subjectErrorMessage}</ErrorMessage>}
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={6}>
                                <InputFieldContainer>
                                    <InnerLabelInputField
                                        data-input-type={inputType.FirstName}
                                        labelText="First Name"
                                        id="firstName"
                                        name="firstName"
                                        disabled={false}
                                        mask={mask.FirstName}
                                        maskType="FirstName"
                                        error={errorMessages.FirstName}
                                        errorText={errorMessages.FirstName}
                                        width="auto"
                                        value={values.firstName}
                                        onChange={handleInputChange}
                                        noValidate
                                        placeholder="&nbsp;"
                                        required
                                        type={type.FirstName}
                                    />
                                </InputFieldContainer>
                            </Col>
                            <Col sm={6}>
                                <InputFieldContainer>
                                    <InnerLabelInputField
                                        data-input-type={inputType.LastName}
                                        labelText="Last Name"
                                        id="lastName"
                                        name="lastName"
                                        disabled={false}
                                        mask={mask.LastName}
                                        maskType="LastName"
                                        error={errorMessages.LastName}
                                        errorText={errorMessages.LastName}
                                        width="auto"
                                        value={values.lastName}
                                        onChange={handleInputChange}
                                        noValidate
                                        placeholder="&nbsp;"
                                        required
                                        type={type.LastName}
                                    />
                                </InputFieldContainer>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={6}>
                                <InputFieldContainer>
                                    <InnerLabelInputField
                                        data-input-type={inputType.Email}
                                        labelText="Your Email"
                                        id="email"
                                        name="fromEmailAddress"
                                        disabled={false}
                                        mask={mask.Email}
                                        maskType="Email"
                                        error={errorMessages.Email}
                                        errorText={errorMessages.Email}
                                        width="auto"
                                        value={values.fromEmailAddress}
                                        onChange={handleInputChange}
                                        noValidate
                                        placeholder="&nbsp;"
                                        required
                                        type={type.Email}
                                    />
                                </InputFieldContainer>
                            </Col>
                            <Col sm={6}>
                                <InputFieldContainer>
                                    <InnerLabelInputField
                                        data-input-type={inputType.PhoneNumber}
                                        labelText="Phone"
                                        id="phone"
                                        name="phoneNumber"
                                        disabled={false}
                                        mask={mask.PhoneNumber}
                                        maskType="PhoneNumber"
                                        error={errorMessages.PhoneNumber}
                                        errorText={errorMessages.PhoneNumber}
                                        width="auto"
                                        value={values.phoneNumber}
                                        onChange={handleInputChange}
                                        noValidate
                                        placeholder="&nbsp;"
                                        type={type.PhoneNumber}
                                    />
                                </InputFieldContainer>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                <CommentFieldContainer>
                                    <MultiLineInputField
                                        data-input-type={inputType.Comments}
                                        label="Comments"
                                        id="comments"
                                        name="comments"
                                        disabled={false}
                                        mask={mask.Comments}
                                        maskType="Comment"
                                        inputWidth="auto"
                                        inputHeight={commentHeight}
                                        isOuter={false}
                                        value={values.comments}
                                        onChange={handleInputChange}
                                        noValidate
                                        placeholder="&nbsp;"
                                        type={type.Comments}
                                    />
                                </CommentFieldContainer>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {reCaptcha}
                                {!validateRecaptcha && isValidateOnChange && <div className="recaptacha-error-message">Verify reCAPTCHA is required</div>}
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={isModal ? 5 : 7}>
                                {privatePolicyUrl && (
                                    <PrivacyPolicyContainer>
                                        <Link onClick={() => onClickPrivacyPolicy()}>Privacy Policy</Link>
                                    </PrivacyPolicyContainer>
                                )}
                            </Col>
                            <Col sm={isModal ? 7 : 5}>
                                <Button
                                    disabled={false}
                                    enableCapitalize={false}
                                    minimizeSize={false}
                                    sizeType="regular"
                                    target="_self"
                                    text="Send Message"
                                    width="100%"
                                />
                            </Col>
                        </Row>
                    </GridContainer>
                </Section>
            </Theme>
        </ContactDealerContainer>
    );
};

const mapStateToProps = (state) => {
    const user = _get(state, 'user', {});
    const { location } = user;
    const currentPageName = _get(state, 'currentPageName', '');
    const activeOwner = inventoryActiveOwnerDuck.selectors.getDuckState(state);
    const owner = _get(state.owners, activeOwner, {});
    const selectedTab = getSelectedTabFromState(state);
    const isServiceTab = (selectedTab?.name === TAB_NAME.SERVICE);
    const defaultSubjectValue = isServiceTab ? EMAIL_SUBJECT.VEHICLE_SERVICE_AND_REPAIR.value : EMAIL_SUBJECT.GENERAL_VEHICLE_INFO.value;
    const inventoryKeys = Object.keys(state.inventory);
    const inventoryId = inventoryKeys && inventoryKeys.length > 0 ? inventoryKeys[0] : '';

    return {
        currentPageName,
        vdpType: '',
        ownerId: owner.id,
        adobeTransId: `Lead_${uuidv1()}`,
        searchZip: location ? location.zip : null,
        ccFlag: true,
        promotionsFlag: true,
        originPage: currentPageName,
        deal: '',
        isNDSCalc: false,
        listingId: '',
        jornayaLeadId: '',
        textPot: undefined,
        clickType: undefined,
        bookmarkTitle: undefined,
        includeWalletData: undefined,
        isServiceTab,
        defaultSubjectValue,
        inventoryId,
    };
};
const mapDispatchToProps = mapDispatchToActionProp({
    setShowingContactDealer: showContactDealerDuck.creators.setShowingContactDealer,
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactDealer);
