import React, {
    useState,
    useEffect,
} from 'react';

import dynamic from 'next/dynamic';
import LazyComponent from '../components/LazyComponent';

import {
    StaticFooter,
} from 'reaxl-footer';
import { footer } from 'axl-config';
import { addBaseUrlToNavigation } from 'atc-js';
import { useFeatures } from 'reaxl-features';
import { sendClick } from 'reaxl-analytics';

import { getNavigationOverrides } from '../reference';

const FooterContentInjector = dynamic(() => import('reaxl-footer/es/FooterContentInjector'), { ssr: false });

const ATCFooterContainer = () => {
    const [isClient, setIsClient] = useState(false);

    useEffect(() => {
        setIsClient(true);
    }, []);

    const {
        disable_ads: [NO_ADS],
        disable_thirdparty: [NO_THIRD_PARTY],
        col_enable_one_trust_footer: [colEnableOneTrustFooter],
    } = useFeatures([
        'disable_ads',
        'disable_thirdparty',
        'col_enable_one_trust_footer',
    ]);

    // skip loading audioeye
    const disableThirdpartyScripts = NO_ADS || NO_THIRD_PARTY;

    const handleFooterClick = (event, data) => {
        sendClick('footerLinkClick', event, { ...data });
    };

    return (
        <>
            <StaticFooter
                sitemap={addBaseUrlToNavigation(footer.sitemap, getNavigationOverrides())}
                onClick={handleFooterClick}
                enableOneTrustFooter={colEnableOneTrustFooter}
            />
            {isClient && !disableThirdpartyScripts && (
                <LazyComponent>
                    <FooterContentInjector />
                </LazyComponent>
            )}
        </>
    );
};
export default ATCFooterContainer;
