import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import getConfig from 'next/config';
import { useRouter } from 'next/router';
import { mapDispatchToActionProp } from '@atc/modular-redux';
import { TAB_NAME } from '../../../constants';
import KbbDealerRatingBadge from '../../KbbDealerRatingBadge';
import { DealerCTAsContainer, DealerHomeServicesContainer } from '.';
import { userDuck, dealerTabsDuck, inventoryActiveOwnerDuck } from '../../../ducks';
import CarouselDealer from '../../../components/Carousel/CarouselDealer';
import { defaultImage, kbbBrand, atcBrand, dealerRatingPageType } from '../../../constants';
import getWidthAndHeight from '../../../utilities/getWidthAndHeight';
import EmbeddedWidget from '../../../components/EmbeddedWidget/EmbeddedWidget';
import styled from '@emotion/styled';
import { useBrand } from 'reaxl-brand';
import { resetTab, getTabIndexByName, getMilesAway } from '../../../utilities';
import { setWidth } from '../../../utilities/helpers';

import { FlexBox, Section, colors } from '@argo/principles';
import { MapMarkerIcon, Paragraph } from '@argo/ui-elements';
import { debounce } from '@argo/utils';

const DealerRatingAndDistanceSection = styled(Section)`
    ${({ imageWidth }) => setWidth(imageWidth)}
    div[data-cmp="dealerRatingAndDistanceSection"] {
        padding: 8px 0px 8px 16px;
        @media (min-width: 564px) {
            padding: 8px 0px 8px 0px;
        }
    }
`;

const DealerCTAsSection = styled(Section)`
    div[data-cmp="dealerCTAsSection"] {
        padding: 0px 16px 24px 16px;
        @media (min-width: 564px) {
            padding: 0px 16px 64px 16px;
        }

        @media (min-width: 768px) {
            padding: 8px 0px 8px 0px;
        }
    }
`;

const DealerDistanceContainer = styled.div`
    flex-basis: 94px;
    ${({ isKbb }) => (isKbb ? 'div{div{margin-left: -24px;}}' : 'div{margin-left: -16px;}')}
    margin-left: auto;
`;

const AboutTabContainer = ({
    actions,
    owner,
    imageData = [],
    reviewsTabIndex,
    cityZip,
    pageId,
}) => {
    const { isBrand } = useBrand();
    const router = useRouter();
    const isKbb = isBrand(kbbBrand);
    const brandName = isKbb ? kbbBrand : atcBrand;
    const [imageWidth, setImageWidth] = useState('500px');
    const [imageHeight, setImageHeight] = useState('320px');
    const { rating = {} } = owner;
    const kbbRatingValue = _get(rating, 'value', 0);
    const { publicRuntimeConfig } = getConfig();
    const zip = _get(owner, 'location.address.zip', '');
    const kbbDealerRatingUrl = `${publicRuntimeConfig.kbbRatingBadgeUrl}&svocId=${owner.id}&zipCode=${zip}&searchZip=${cityZip || zip}&pageId=${pageId}&pageType=${dealerRatingPageType}&pageName=ddp&hostSite=${brandName}&debug=false`;

    const resizeCallback = useCallback((clientWidth) => {
        getWidthAndHeight(clientWidth, setImageWidth, setImageHeight);
    }, []);
    useEffect(() => {
        window.addEventListener('message', (event) => {
            if (event.origin !== kbbDealerRatingUrl.split('/widget')[0]) return;
            if (event.data.eventType === 'click'
                && event.data.experience === 'inline') {
                actions.setSelectedTab(reviewsTabIndex, TAB_NAME.REVIEWS);
                resetTab(router, TAB_NAME.REVIEWS);
            }
        });
        const dealerWrapper = document.getElementById('__next');
        resizeCallback(dealerWrapper?.clientWidth);
        const { invoke } = debounce(() => {
            resizeCallback(dealerWrapper?.clientWidth);
        }, 200);

        window.addEventListener('resize', invoke);
        return () => window.removeEventListener('resize', invoke);
    }, []);

    return (
        <>
            <Section
                id="about"
                data-cmp="aboutTabSection"
                fullWidth
                bgColor={colors.neutral.offWhite}
                verticalSpacingOverrides={{ top: '0', bottom: '40px' }}
                horizontalSpacingOverrides={{ left: '0', right: '0' }}
            >
                <FlexBox
                    flexWrap="wrap"
                    default={[5, 7]}
                    sm={['0 0 100%', '0 0 100%']}
                    md={[5, 7]}
                    verticalSpacing="none"
                    horizontalSpacing="none"
                >
                    <FlexBox
                        flowColumn
                        verticalSpacing="none"
                        horizontalSpacing="none"
                    >
                        <CarouselDealer
                            imageData={imageData}
                            imageWidth={imageWidth}
                            imageHeight={imageHeight}
                        />

                        <DealerRatingAndDistanceSection
                            data-cmp="dealerRatingAndDistanceSection"
                            imageWidth={imageWidth}
                            bgColor="transparent"
                        >
                            <FlexBox
                                data-cmp="dealerRatingContainer"
                                className="dealerRating"
                                justifyContent="space-between"
                                horizontalSpacing="none"
                            >
                                {kbbRatingValue > 0 ? (
                                    <KbbDealerRatingBadge
                                        kbbDealerRatingUrl={kbbDealerRatingUrl}
                                        isImpression
                                    />
                                ) : <></>}
                                <DealerDistanceContainer isKbb={isKbb}>
                                    <FlexBox
                                        className="dealer-distance-2"
                                        verticalSpacing="none"
                                        horizontalSpacing="none"
                                    >
                                        <MapMarkerIcon
                                            className="margin-right-0"
                                            color={isKbb ? 'darkBrightBlue' : '#E85B00'}
                                            size="16"
                                        />
                                        <div className="margin-left-0">
                                            {getMilesAway(owner.distanceFromSearch)}
                                        </div>
                                    </FlexBox>
                                </DealerDistanceContainer>
                            </FlexBox>
                            <div className="margin-top-4 margin-bottom-0">
                                <Paragraph>
                                    {owner.dealerDiff?.tagline}
                                </Paragraph>
                            </div>
                        </DealerRatingAndDistanceSection>
                    </FlexBox>
                    {owner.id && (
                        <DealerCTAsSection
                            data-cmp="dealerCTAsSection"
                            fullWidth
                            bgColor="transparent"
                        >
                            <DealerCTAsContainer />
                        </DealerCTAsSection>
                    )}
                </FlexBox>
            </Section>
            <EmbeddedWidget parentTab={TAB_NAME.ABOUT} />
            {owner.homeServices && <DealerHomeServicesContainer />}
        </>
    );
};

function mapStateToProps(state) {
    const activeOwner = inventoryActiveOwnerDuck.selectors.getDuckState(state);
    const owner = _get(state.owners, activeOwner, {});
    const reviewsTabIndex = getTabIndexByName(state, TAB_NAME.REVIEWS);
    const cityZip = _get(userDuck.selectors.getLocation(state), 'zip', '');
    const pageId = _get(state, 'birf.pageData.page.BIRF.pg', '');
    return {
        owner,
        imageData: _get(owner, 'images.sources', defaultImage),
        reviewsTabIndex,
        cityZip,
        pageId,
    };
}

const mapDispatchToProps = mapDispatchToActionProp({
    setSelectedTab: dealerTabsDuck.creators.setSelectedTab,
});

export default connect(mapStateToProps, mapDispatchToProps)(AboutTabContainer);
