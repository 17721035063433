/* eslint-disable no-console */
import fetch from '@bonnet/next/fetch';

export default async function emailFetcher(data) {
    try {
        const response = await fetch('/car-dealers/email-service', {
            method: 'POST',
            body: JSON.stringify({ ...data }),
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
            },
        });

        if (!response) {
            throw new Error('No response received from the server');
        }

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return await response.json();
    } catch (error) {
        console.error('Error fetching email service:', error);
        throw error;
    }
}
