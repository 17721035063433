import React, { memo } from 'react';
import { mapDispatchToActionProp } from '@atc/modular-redux';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import { ddpContactType } from '../../constants';

// components
import { ConnectedEmailOwner } from 'reaxl-email';

// ducks
import {
    paymentsDuck,
    userDuck,
    authDuck,
    inventoryActiveEmailListingDuck,
    inventoryActiveInteractionDuck,
    inventoryFiltersDuck,
} from '../../ducks';

// fetchers
import emailFetcher from '../../fetchers/emailFetcher';

const SearchResultsEmailModalContainer = memo(({
    actions: {
        setActiveInteraction,
        setLeadProfile,
        updateMyWallet,
    },
    clickType,
    emailCTAContext,
    isLoggedIn,
    ownerId,
    parentId,
    showEmailModal,
}) => {

    // default ui context for srp listings should be 'cta'
    const handleOnHide = () => {
        setActiveInteraction({
            emailCTAContext: '',
            showEmailModal: false,
        });
    };

    const handleEmailFetcher = async (data) => {
        const transformedData = {
            ...data,
            promotionsFlag: data?.optIn,
            carId: data?.listingId,
            ccFlag: data?.copySelf,
            contactType: ddpContactType.LISTING_FORM,
        };

        ['optIn', 'listingId', 'copySelf'].forEach((prop) => delete transformedData[prop]);

        const response = await emailFetcher(transformedData);
        return response;
    };

    const emailOwnerProps = {
        activeEmailListingDuck: inventoryActiveEmailListingDuck,
        clickType,
        ctaProps: {
            context: emailCTAContext,
            onHide: handleOnHide,
            show: showEmailModal,
        },
        fetcher: handleEmailFetcher,
        ownerId,
        parentId,
        setLeadProfile,
        uiContext: 'cta',
        updateMyWallet,
        isLoggedIn,
        userDuck,
    };

    return showEmailModal && (
        <ConnectedEmailOwner
            {...emailOwnerProps}
        />
    );
});

const mapStateToProps = (state) => {
    // should only be set by email components without listings
    const inventoryActiveInteractionData = inventoryActiveInteractionDuck.selectors.getDuckState(state);
    const ownerId = _get(inventoryActiveInteractionData, 'ownerId', '');
    const showEmailModal = _get(inventoryActiveInteractionData, 'showEmailModal', false);
    const parentId = _get(inventoryActiveInteractionData, 'parentId', '');
    const emailCTAContext = _get(inventoryActiveInteractionData, 'emailCTAContext', '');
    const clickType = _get(inventoryActiveInteractionData, 'clickType', '');

    const filtersOptions = inventoryFiltersDuck.selectors.getFiltersOptions(state);
    const filtersValues = inventoryFiltersDuck.selectors.getFiltersValues(state);

    const isLoggedIn = authDuck.selectors.isLoggedIn(state);

    const preorder = _get(state, 'preorder', []);
    const { activeResults = [] } = inventoryActiveEmailListingDuck.selectors.getDuckState(state);
    const listing = activeResults.length > 0 ? preorder[activeResults[0]] : {};

    return {
        clickType,
        emailCTAContext,
        filtersOptions,
        filtersValues,
        isLoggedIn,
        listing,
        ownerId,
        parentId,
        showEmailModal,
    };
};

const mapDispatchToProps = mapDispatchToActionProp({
    setActiveInteraction: inventoryActiveInteractionDuck.creators.setKeys,
    setLeadProfile: userDuck.creators.setLeadProfile,
    updateMyWallet: paymentsDuck.creators.updateMyWallet,
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchResultsEmailModalContainer);
